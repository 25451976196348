.search-input::placeholder {
  color: #BFBEBE !important;
  font-size: 14px;
}
.cursor-pointer {
  cursor: pointer!important;
}
.cursor-pointer:hover {
  background-color: #f5f5f5;
}
.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.donate-btn {
  width: 100%;
  padding: 10px!important;
}
.donate-text {
  text-align: center;
  padding: 10px;
  margin: 0!important;
}